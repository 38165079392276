import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, FormControl, Validators, UntypedFormGroup, UntypedFormArray, FormBuilder } from '@angular/forms';
import { ContactFormService } from 'src/app/contact-form.service';
import { ClientService } from 'src/app/client.service';

declare const google: any;

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss']
})
export class HomeFiveComponent implements OnInit {

  showInfoBox = false;
  map: any;
  markers = [];
  public clientInfo: { [key: string]: string } = {};

  currentSection: string = '';

  public formularioEnviado = false;
  public formLoading = false;
  public isSubmitted = false;

  contactForm = this.formBuilder.group({
    first_name: ['', Validators.required],
    surname: ['', [Validators.required]],
    company_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required],
  });
  ngForm: any;

  selectedClient: any;

  // Método para cambiar la clase de la sección actual
  changeSection(section: string) {
    this.currentSection = section;
    console.log(this.currentSection)
  }

  constructor(
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router,
    public formBuilder: FormBuilder,
    private contactService: ContactFormService,
    private clientService: ClientService
  ) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    // this.getClients();
    this.initMap();
    this.route.queryParamMap.subscribe(queryParams => {
      this.currentSection = queryParams.get('section') || ''; // If no section query parameter, set it to an empty string
      this.highlightActiveSection(this.currentSection);
    });
    this.updateInfoBoxContent();
  }
  iconos = {
    iconoNormal: "/assets/img/Location (1).ico",
    iconoSeleccionado: "/assets/img/SelectedLocation.ico"
  };
  initMap() {
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 4,
      center: { lat: 1.3751339650443164, lng: 32.4574814527082 },
      panControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      gestureHandling: "cooperative",
      rotateControl: false,
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            {
              saturation: -100, // Desaturación para convertir los colores a blanco y gris
            },
            {
              lightness: 40, // Ajusta este valor para controlar el nivel de brillo
            },
          ],
        },
      ],

    });

    this.clientService.getClients().subscribe((response: any) => {
      const clients = response.data;
      console.log(clients);

      let activeMarker: google.maps.Marker | null = null;

      clients.forEach((client: any) => {

        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(client.latitud), lng: parseFloat(client.longitud)
          },
          map: map,
          icon: "/assets/img/distribute_strokeok.png",


        });
        this.markers.push(marker)

        const secretMessages = `
        <strong style="font-size: 8px;">${client.nombre}</strong><br>
        <span style="font-size: 8px;">${client.direccion}</span><br>
        <span style="font-size: 8px;">${client.web}</span><br>
        <span style="font-size: 8px;">${client.email}</span><br>
        <span style="font-size: 8px;">${client.telefono}</span><br>
      `;
        const infowindow = new google.maps.InfoWindow({
          content: secretMessages,
        });

        marker.addListener("click", () => {
          this.selectedClient = client;
          infoBox.style.display = "block"; // Mostrar el recuadro fijo
          this.updateInfoBoxContent();
          if (activeMarker) {
            activeMarker.setIcon("/assets/img/distribute_strokeok.png");
          }
          marker.setIcon("/assets/img/distribute_stroke.png");
          activeMarker = marker;
          
        });
        this.markers.push(marker);

      });
      console.log(this.markers)
    });

    // Crear recuadro fijo
    const infoBox = document.createElement("div");
    infoBox.id = "infoBox";
    infoBox.style.position = "absolute";
    infoBox.style.display = "none";
    infoBox.style.backgroundColor = "white";
    infoBox.style.padding = "10px";
    infoBox.style.border = "none";
    infoBox.style.zIndex = "1";
    infoBox.innerHTML = `

    `;
    document.getElementById("map").appendChild(infoBox);

    infoBox.addEventListener("click", () => {
      // Acción al hacer clic en el recuadro fijo
    });
  }

  highlightActiveSection(sectionId: string): void {
    const navLinks = document.querySelectorAll('.navbar-nav .link-btn');
    navLinks.forEach(link => {
      link.classList.remove('active');
      const routerLink = link.getAttribute('href');
      if (routerLink && routerLink === `/${sectionId}`) {
        link.classList.add('active');
      }
    });
  }

  submit() {
    this.formLoading = true;
    this.isSubmitted = true;
    if (this.contactForm.invalid) {
      console.log(this.contactForm);
      this.formLoading = false;
      return;
    }

    this.contactService.sendForm(this.contactForm.value).subscribe({
      next: (r: any) => {
        console.log(r);
        this.formularioEnviado = true;
        this.formLoading = false;
      },
      error: (e) => console.log(e)
    });
  }

  updateInfoBoxContent() {
    const infoBox = document.getElementById("infoBox");
    if (infoBox && this.selectedClient) {
      infoBox.innerHTML = `
      <p style="font-size: 14px;"><strong style="font-size: 16px;">${this.selectedClient.nombre}</strong></p>
      ${this.selectedClient.direccion !== null ? `
        <div>
          <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong>${this.selectedClient.direccion}</p>
        </div>
      ` : ''}
      ${this.selectedClient.web !== null ? `
        <div>
        <a style="font-size: 14px;" href="http://${this.selectedClient.web}" target="_blank">
        <strong style="font-size: 16px;">
          <span style="color:#3A9B43;">${this.selectedClient.web}</span>
        </strong>
      </a>
      
      
        </div>
        ` : ''}
        ${this.selectedClient.email !== null ? `
        <div>
        <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong> ${this.selectedClient.email}</p>
        </div>
        ` : ''}
        ${this.selectedClient.email !== null ? `
        <div>
        <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong> ${this.selectedClient.telefono}</p>
        </div>
        ` : ''}

      `;
    }
  }

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 22.2736308,
    lng: 70.7512555
  };
  zoom = 6;

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

}
