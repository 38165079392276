<footer class="footer-area">
    <div class="container">
        <div class="row" style="margin-bottom: 0;">
          <div class="col-md-6 ">
            <img src="/assets/img/Logo_Zellsolar_Fondo_blanco_PNG-removebg-preview.png" height="100" alt="Logo">
          </div>
          <div class="col-md-4 d-flex align-items-end" style="margin-left: 7em;">
            <h2>MEMBER OF:</h2>
          </div>
          
          
        </div>

          <div class="row ">
            <div class="row col-md-5 borders-top me-3">
              <div class="col-5">
              <p class=" ">Contact Us</p>
              <p class="mt-3 fuente-pop" style="color:#3A9B43;font-size:small">GENERAL INQUIRIES</p>
              <p style="font-size:small" class="texto-negro"> +27 76 236 8949 </p>
              <p style="font-size:small" class="texto-negro">info@zellsolar.co.za </p>
              <p style="font-size:small">E105 First On Forest, Thornton, Cape Town</p>
              <p style="font-size:small">ZELLSOLAR PTY</p>

            </div>
              <div class="col-5">
              <p class="fuente-pop" style="color:#3A9B43; font-size:small; margin-top: 3.2em">TECHNICAL SUPPORT</p>
              <p style="font-size:small" class="texto-negro">+27 76 250 5488 
              </p>
              <p style="font-size:small" class="texto-negro">service@zellsolar.co.za
              </p>
            </div>
            </div>
            <div class="col-md-2 borders-top me-3" >
              <p class="">Follow us at</p>
              <a style="font-size:small"class="texto-negro mt-3" href="https://www.linkedin.com/company/zellsolar-pty/" target="_blank" >Linkedin</a><br>
              <a style="font-size:small"class="texto-negro"href="https://instagram.com/zellsolarpty?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank">Instagram</a><br>
              <a style="font-size:small"class="texto-negro"href="https://www.facebook.com/profile.php?id=61552214943827" target="_blank">Facebook</a>
            </div>

            <div class="col-md-4" style="margin-left: 5em;">
              <img src="/assets/img/FD-LV-removebg-preview (1).png"  height="180" alt="Logo">
            </div>
            
            
  

          </div>
      </div>
</footer>

