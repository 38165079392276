<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
          <br><br>
            <div class="battery-cea-home" >
                <span style="color:#EFE6C0; opacity:0.3" >BYD <br> BATTERYBOX</span><br><br>
            </div> 
    <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp-2 text-center d-flex flex-column align-items-center justify-content-center">
                <img src="assets/img/fd-lvg-removebg-preview.png" style="width: 250px; height: 200px;padding-top:5em;" alt="services-img"> 
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">FD-LV5.0
                </h3>
                <ul>
                    <li class="mt-2">
                      <a href="assets/pdf/FDLV/DATASHEET.pdf" target="_blank" class="text-crema">DATASHEET</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/FDLV/QUICK START GUIDE.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/FDLV/USER MANUAL.pdf" target="_blank" class="text-crema">USER MANUAL</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/FDLV/SERVICE GUIDELINE.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE</a>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp-2 text-center d-flex flex-column align-items-center justify-content-center">
                <img src="assets/img/LVL Frontal.webp" style="width: auto; height: 100%;padding-top:5em;" alt="services-img"> 
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD Premium LVL</h3>
                <ul>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/230318_Premium_Datasheet_LVL V1.2 EN-6415490d0be9d.pdf" target="_blank" class="text-crema">DATASHEET</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/230922_BYD_Battery-Box_Premium_LVL_Minimum_Configuration_List_V2.3-650d5c7661417.pdf" target="_blank" class="text-crema">TECHNICAL INFORMATION in South Africa</a>
                    </li >
                    <li class="mt-2" style="white-space: nowrap;">
                      <a href="assets/pdf/LVL/BYD Battery-Box Premium LVL Limited Warranty-Residential-Middle East and Africa-EN V1.1-61a87d3ce9d6f.pdf" target="_blank" class="text-crema">TECHNICAL INFORMATION in the rest of Africa</a>
                    </li>
                    
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/BYD Battery-Box Premium Quick Start Guide LVL-V1.3-5f34bb723234d.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/BYD Battery-Box Premium Operating Manual LVL V1.2-600fb4fd1d7ca.pdf" target="_blank" class="text-crema">OPERATING MANUAL</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/2022-01-11_BYD_Battery-Box_Premium_LVL_Service_Guideline_and_Checklist_EN_V1.2-620b633a4f403.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/BYD Battery-Box Premium LVL Limited Warranty-Residential-South Africa-EN V1.2-61a87d88f1dd5.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/LVL/Battery-Box LVL FAQs.pptx" target="_blank" class="text-crema">FAQS</a>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp text-center "style="display: flex; flex-direction: column; align-items: end;">
              <div style="flex-grow: 0.6;"></div>
              <img src="assets/img/LV FLEX LITE.png"  style="width: auto; height: 80%; padding-top:3em" class="img-centrada-resp" alt="services-img">
            </div>
            <div class="services-content text-center">
              <h3 class="texto-blanco fuente-cool-light">BYD LV Flex Lite</h3>
              <ul class="text-center">
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/230830_BYD_Battery-Box_LV_Flex_Lite_Datasheet_V1.3_EN-64ef10e1cdb7e.pdf" target="_blank" class="text-crema">DATASHEET</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/240102_BYD_Battery-Box_LV_Flex_Lite_Minimum_Configuration_List_V1.4-6594b021c2707.pdf" target="_blank" class="text-crema">TECHNICAL INFORMATION</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/230608_BYD Battery-Box LV Flex Lite Quick Start Guide V2.2-6492fbd995084.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/Battery-Box LV Flex Lite Operating Manual V1.0-61a03aa496a45.pdf" target="_blank" class="text-crema">OPERATING MANUAL</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/2022-2-8 BYD Battery-Box LV FLEX LITE Service Guideline and Checklist-620b6dfeb5856.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/BYD Battery-Box LV Flex Lite Limited Warranty-Residential-South Africa-EN V1.1-619cb9348d4dc.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/LVLLITE/Battery-Box LV Flex Lite FAQs.pptx" target="_blank" class="text-crema">FAQS</a>
                </li>
              </ul>
            </div>
          </div>
          
        <div class="col-lg-3 col-md-6">
            <div class="container-img  text-center">
      <img src="assets/img/HVS.webp" alt="services-img" style="width: 70%; height: auto;padding-top:6em;">
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD HVS / HVM</h3>
                <ul>
                  <li class="mt-2">
                    <a href="assets/pdf/HVS/231122_BYD_Battery-Box_Premium_HVS&HVM_Datasheet_V1.8_EN-655eaa1bcd31f.pdf" target="_blank" class="text-crema">DATASHEET</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/230811_BYD_Battery_Box_Premium_HVS&HVM_Compatible_Inverter_List_V2.12-64dc9187761a1 (1).pdf" target="_blank" class="text-crema">COMPATIBILITY LIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/HVS/230821_BYD_Battery-Box_Premium_HVS&HVM_Quick_Start_Guide_V2.7-64e2d6b5a0848.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/HVS/231122_BYD_Battery-Box_Premium_HVS&HVM_Operating_Manual_V2.1_EN-655ea6ba188f6.pdf" target="_blank" class="text-crema">OPERATING MANUAL</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/HVS/2022-01-11_BYD_Battery-Box_Premium_HVS_HVM_Service_Guideline_and_Checklist_EN_V1.5-620b5f836f34c.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/HVS/BYD Battery-Box Premium HVS&HVM Limited Warranty-South Africa-EN V1.1-61a87399f3c9e.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                  </li>
                  </ul>         
            </div>
        </div>
      </div><br>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="container-img-resp-1 container-img-bmu text-center">
            <img src="assets/img/LV BMU.png" height="300" alt="services-img">
          </div>
          <div class="services-content text-center">
            <h3 class="texto-blanco fuente-cool-light">BYD LV BMU</h3>
            <ul class="texto-crema text-center">
              <li class="mt-2 ">
                <a href="assets/pdf/BYD Battery-Box Premium LV BMU- IP55 Instruction Manual.pdf" target="_blank" style="margin-right: 2em;" class="text-crema">INSTRUCTION MANUAL</a>
              </li>
            </ul>
          </div><br>
        </div>
        <div class="col-lg-4 col-md-6">
      </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            
          </div>
        </div>
      </div>
      
      
    </div>
</div>
</div>
