import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactFormService } from 'src/app/contact-form.service';
import { EmailService } from 'src/app/email.service';

@Component({
  selector: 'app-home-six',
  templateUrl: './home-six.component.html',
  styleUrls: ['./home-six.component.scss']
})
export class HomeSixComponent implements OnInit {

  public formularioEnviado = false;
  public formLoading = false;
  public isSubmitted = false;

  contactForm = this.formBuilder.group({
    first_name: ['', Validators.required],
    surname: ['', [Validators.required]],
    company_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required],
  });
  ngForm: any;

  constructor(
    private emailService: EmailService,
    public formBuilder: FormBuilder,
    private contactService: ContactFormService
  ) {}

  ngOnInit() {}



  enviarCorreoTareaCompletada(tarea: string) {
    const destinatario = 'destinatario@example.com'; // Reemplaza con la dirección de correo deseada
    const asunto = 'Tarea Completada';
    const mensaje = `La tarea "${tarea}" ha sido completada.`;

    this.emailService.enviarCorreo(destinatario, asunto, mensaje).subscribe({
      next: (response) => {
        console.log('Correo enviado:', response);
        // Realiza cualquier acción adicional que desees después de enviar el correo aquí.
      },
      error: (error) => {
        console.error('Error al enviar el correo:', error);
        // Maneja el error si es necesario.
      },
    });
  }


  openGmailWithRecipient() {
    const recipientEmail = 'info@zellsolar.co.za';
    const subject = 'Contact';
    const firstName = this.contactForm.get('first_name').value;
    const surname = this.contactForm.get('surname').value;
    const companyName = this.contactForm.get('company_name').value;
    const email = this.contactForm.get('email').value;
    const message = this.contactForm.get('message').value;

    // Construir el cuerpo del mensaje con los valores de los campos
    const body = `First name: ${firstName}\nSurname: ${surname}\nCompany: ${companyName}\nEmail: ${email}\nMessaje: ${message}`;

    // Codificar los parámetros para la URL
    const encodedRecipient = encodeURIComponent(recipientEmail);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    // Construir la URL de Gmail con el destinatario, el asunto y el cuerpo
    const gmailURL = `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=${encodedRecipient}&su=${encodedSubject}&body=${encodedBody}`;

    // Abrir una nueva ventana o pestaña con Gmail
    window.open(gmailURL, '_blank');
  }
}
