<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
  <div class="container">
    <a class="navbar-brand" routerLink="/Home">
      <img src="/assets/img/Logo Zellsolar_Fondo verde oscuro_PNG.png" height="55" alt="Logo">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleClass()">
      <span class="burger-menu">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </span>
    </button>
    <div class="collapse navbar-collapse " style="margin-left:5em" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" >


        <li class="nav-item" [class.active]="currentSection === 'about-us'">
          <div class="menu-item "  [class.active]="isProductsActive" (click)="closeSubMenuOnOutsideClick()">
            <a [routerLink]="['/about-us']" [queryParams]="{ section: 'about-us' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section2'); closeMenu()">
              About us</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'byd-betterybox'">
          <div>
            <a [routerLink]="['/byd-betterybox']" [queryParams]="{ section: 'byd-betterybox' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section2'); closeMenu()">
              Products</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'solutions'">
          <div>
            <a [routerLink]="['/solutions']" [queryParams]="{ section: 'solutions' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section1'); closeMenu()">
              C&I Soluction</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'Contact'">
          <div>
            <a [routerLink]="['/Contact']" [queryParams]="{ section: 'Contact' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section5'); closeMenu()">
              Contact</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item text-right">
          <div>
            <!-- el boton lleva este estilo cuando este online service center style="margin-top: 2.5em; margin-right: 5.5em;" -->
            <button class="btn btn-primary" style="margin-top: 2.5em; margin-right: 5.5em;">
              <a [routerLink]="['/Downloads']" [queryParams]="{ section: 'Downloads' }" (click)="closeMenu()">
                <span class="texto-blanco texto-secciones" style="border-bottom: transparent !important;">
                  Downloads</span>
              </a>
            </button>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'Online-service-center'">
          <div>
            <a href="https://support.zellsolar.co.za/" target="_blank" class="link-btn texto-secciones online-margin" (click)="changeSection('section6'); closeMenu()" style="margin-right: 1em;">
              <span style="color:#3A9B43">
                Online service center</span>
            </a>
          </div>
        </li> 
        <!-- <li class="nav-item" [class.active]="currentSection === 'Online-service-center'">
          <div>
            <a target="_blank" class="link-btn texto-secciones online-margin" (click)="changeSection('section6'); closeMenu();" style="margin-right: 1em;">
              <span style="color:#3A9B43">
                Online service center</span>
            </a>
          </div>
        </li> -->

      </ul>
      <ul class="navbar-nav ml-left for-responsive">
        <li class="nav-item"><span class="nav-link" routerLink="/about-us" (click)="closeMenu()">About us</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/byd-betterybox" (click)="closeMenu()">Products</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/solutions" (click)="closeMenu()">C&I Solutions</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Contact" (click)="closeMenu()">Contact</span></li>
        <li class="nav-item"><span class="nav-link" style="color: #3A9B43;" routerLink="/Downloads" (click)="closeMenu()">DOWNLOADS</span></li>
        <li class="nav-item"><span class="nav-link" href="https://support.zellsolar.co.za/" target="_blank" (click)="closeMenu()">Online service center</span></li> 
      </ul>
    </div>
  </div>
</nav>
