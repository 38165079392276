<div class="main-banner-home "> 
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-texts">
                    <h1 class=" mobile-font-size-sm ">Powering Africa with <br > BYD Technology</h1>
                    <p >Energy storage solutions delivered and serviced locally.</p><br><br><br>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banners main-banner-homes">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="card-cea"style="opacity: 0.96; height: 550px;">
                   <div class="card-body"style="padding-top: 20px; padding-bottom: 20px;" >
                    <div class="row align-items-center ">
                      <div class="col-lg-5 col-md-12 col-sm-12">
                          <div class="about-content">
                              <div class="section-title">
                                  <h1 class="what-cea titulocea "style="text-align: left">What we do</h1><br>
                                  <p class="fuente line-cea">Zellsolar is an authorized distributor of the BYD Battery-Box product range within the Southern African market. Based in South Africa, our commitment goes beyond sales. We provide comprehensive training, expert advisory services, continuous product support, and technical assistance.
                                    <br><br>
                                    Thanks to Zellsolar the procurement and delivery process of BYD solar batteries, spare parts or other additional components is accelerated. We invite you to get to know us better.</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-7 col-md-12 text-center justify-content-center " >
                        <div class="row">
                          <div class="col-6 mt-5 d-flex justify-content-center align-items-center ">
                            <img src="assets/img/fd-lvg.png" alt="about">
                          </div>
                          <div class="col-6 mt-5 d-flex justify-content-center align-items-center">
                            <img class="" src="assets/img/LV FLEX LITE.png" alt="about">
                          </div>
                        </div>
                      </div>
                      
                      
                  </div>
                  
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd   ptb-100">
    <div class="container">
        <div class="section">
<div class="">
    <div class="section">
        <div class="battery-cea-homes bettery mb-5">
            <span >WHY ZELLSOLAR?</span><br>
            </div>   
            <div class="row justify-content-center equal-height pb-100">
            <div class="col-lg-3 col-md-6">
                <div class="">
                    <div class="services-content mb-2">
                        <h3 class="borders-top">Local  <br> Presence</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/LocalPresence.jpeg" height="250" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="">
                  <div class="services-content mb-2">
                      <h3 class="borders-top">Local  <br> Warehousing</h3> <br>                            
                  </div>
                  <div class="services-img">
                      <img src="assets/img/LocalWarehousing.jpg" height="250" alt="services-img">
                  </div>
              </div>
          </div>
          
<div class="col-lg-3 col-md-6">
    <div class="">
        <div class="services-content mb-2">
            <h3 class="borders-top">Local  <br> Training</h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/LocalTraining.jpg" height="250" alt="services-img">
        </div>
    </div>
</div>
<div class="col-lg-3 col-md-6">
    <div class="">
        <div class="services-content mb-2">
            <h3 class="borders-top">Local  <br> Support<br></h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/LocalSupport.jpg" height="250" alt="services-img">
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
          <div class="battery-cea-home text-center mb-5">
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BYD</span>
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BATTERYBOX</span>
        </div>
    </div>
</div>
</div>
<div class="main-banner ">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-texts">
            <h2 class="texto-blanco">The simplest and easiest energy <br> storage system:</h2> <br>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img">
                    <img src="assets/img/expansion.svg" height="200" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple layout</h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/layout.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple expansion </h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/powerful.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simply powerful</h3><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
