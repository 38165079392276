import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isMenuOpen: boolean = false;
  isMenuCollapsed = true;
  isSubMenuActive = false; // Agregamos esta variable
  isProductsActive = false;
  showSubmenu = false;



  currentSection: string = ''; 


  changeSection(section: string) {
    this.currentSection = section;
    console.log(this.currentSection)
  }

  constructor(private viewportScroller: ViewportScroller, private route: ActivatedRoute, private router: Router,public auth: AuthService) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.currentSection = queryParams.get('section') || ''; 
      this.highlightActiveSection(this.currentSection);
    });

  }
  closeMenu() {
    this.isMenuOpen = false;
    this.classApplied = false; // Asegura que el menú colapsado también se oculte
    this.isSubMenuActive = false;

  }
  
  classApplied = false;
  toggleClass() {
      this.classApplied = !this.classApplied;
  }
  

  onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.highlightActiveSection(elementId);
    this.isMenuOpen = false; 
  }

  highlightActiveSection(sectionId: string): void {
    const navLinks = document.querySelectorAll('.navbar-nav .link-btn');
    navLinks.forEach(link => {
      link.classList.remove('active');
      const routerLink = link.getAttribute('href');
      if (routerLink && routerLink === `/${sectionId}`) {
        link.classList.add('active');
      }
    });
  }
  toggleSubMenu() {
    this.isSubMenuActive = !this.isSubMenuActive;
  }

  // Método para cerrar el submenú cuando se hace clic en una opción del submenú
  closeSubMenu() {
    this.isSubMenuActive = false;
  }

  // Método para cerrar el submenú cuando se hace clic en otra parte del navbar
  closeSubMenuOnOutsideClick() {
    if (this.isSubMenuActive) {
      this.isSubMenuActive = false;
    }
  }
  login(){
    this.auth.loginWithRedirect()
    this.auth.isAuthenticated$.subscribe(isAuthenticated =>{
      if(isAuthenticated)
      window.location.href = 'https://support.collective-energy.africa/admin';
    })
  }
  logout(){
    this.auth.logout()
  }
}