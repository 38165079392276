<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">ZELLSOLAR & BYD</h1>
            <p style="color:#EFE6C0; font-size:large">The energy for a better tomorrow</p><br>
            <div class="row justify-content-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner main-banner-imagen" style="height: 1100px;">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-12 col-md-12">
                  <div class="about-content">
                    <div class="text-start ">
                      <h1 style="text-align: left; font-size: 50px">Welcome to Zellsolar!</h1><br>
                      <p class="fuente-pop-reg texto-justificado">Zellsolar is the distinguished South African branch of <a routerLink="/home"><span style="color:#3A9B43">Collective Energy Africa (CEA)</span></a> , a prominent distributor of energy storage solutions and solar technologies, originally established in Kenya in the year 2021. Since its inception, CEA has not only successfully established itself in the East African market but has also pioneered the growth of the market and cultivated its customer base from the ground up, especially given the relatively limited awareness and adoption of lithium batteries in the region. As distributors of BYD, a global leader in lithium battery technology, we are driven to propel the evolution of sustainable energy storage systems across the African continent, revolutionizing how communities and businesses manage and harness energy.</p>
                      <h6 class="mt-2 mb-2" style="font-weight: bolder;"><br> Our Mission</h6>
                      <p class="fuente-pop-reg texto-justificado ">At Zellsolar, our mission is clear: to empower individuals and businesses to adopt advanced
                        energy storage solutions. We believe in a future where clean and reliable energy is accessible
                        to all, especially considering the current energy landscape in the region. Our approach is
                        centered on our three foundational pillars: Product Inventory, Specialized Training, and After-
                        Sales Service, all seamlessly integrated to reflect the depth of our commitment.</p>
                        <h6 class="mt-2 mb-2" style="font-weight: bolder;"> <br> Leadership in Lithium Battery Technology by BYD</h6>
                        <p class="texto-justificado fuente-pop-reg">As distributors of BYD, Zellsolar takes pride in collaborating with a world leader in the lithium
                          battery industry. BYD&#39;s innovation and quality in energy storage technology are unparalleled.
                          This partnership enables us to offer cutting-edge energy storage solutions, backed by the most</p>
                          <p class="fuente-pop-reg">reliable and efficient lithium batteries available.</p> 
                          <h6 class="mt-2 mb-2" style="font-weight: bolder;"><br> Join the Energy Revolution with Zellsolar</h6>
                          <p class="fuente-pop-reg">At Zellsolar, we believe that the transition to a sustainable energy future is a collaborative
                            endeavor. We invite you to join us on this exciting journey towards a world where efficient and
                            sustainable energy storage, powered by BYD&#39;s technology, forms the foundation of a cleaner 
                            and more prosperous society.
                            Thank you for choosing Zellsolar and for being a part of the energy storage revolution in Africa.</p>
                            <h6 class="mt-2 mb-2" style="font-weight: bolder;"> <br> The Zellsolar Team</h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 text-center">
                  <div class="about-video">
                    <img src="assets/img/Imagotipo Zellsolar_Fondo blanco_JPG.jpg" style="height: 21rem; "width="auto" alt="about" class="pb-5">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div class="main-banner-byd ptb-100">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="battery battery-cea">
            <span class="m-5" style="color:#FFFFFF">BYD PARTNERS</span>
          </div>
          <div class="card-byd" style="opacity: 0.9;">
            <div class="card-body" style="padding: 40px;">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="assets/img/cealogos.webp" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <h1 class=""style="text-align: left; font-size: 50px;margin-left:0.8em;">Build Your Dreams</h1><br>
                  <p class="text-justify fuente-pop-reg" style="margin-left:3em; margin-right:3em">BYD (Bluid Your Dreams) is the world's largest manufacturer of solar batteries as well as a leading supplier of electric vehicles. Globally, BYD is one of the most successful, innovative, and fastest-growing companies. <br><br>
                    BYD was founded in February 1995 in Shenzhen, China, with only 20 workers. Today, BYD employs more than 220,000 people worldwide, including production and sales locations in Europe, America, Japan, India, and many more. <br><br>
                    BYD is the first large-scale enterprise involved in the entire energy chain - from generation to storage to consumption to achieve a sustainable energy industry.</p>
                  <div class="button-container">
                    <a href="https://www.bydbatterybox.com/" target="_blank" class="btn btn-three ms-5 fuente-pop-reg">LEARN MORE ABOUT BYD →</a>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-fffdfd ptb-100">
    <div class="container">
      <div class="section">
        <div class="">
          <div class="section">
            <div class="battery">
              <span>0 EMISSION</span>
              <span>ECOSYSTEM</span>
            </div>
            <div class="row justify-content-center equal-height">
              <div class=" small-padd">
                <small class="fuente-pop-reg economy-text">BYD is the world's largest manufacturer of electric vehicles. From electric forklifts to <br>
                  double decker buses in London or the new Skyrail - BYD electrifies all means of <br>
                  transport to create an emission-free ecosystem.
                </small>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="">
                  <div class="services-content">
                    <h3><span class="borders-top">Solar P</span>ower <br> Generation</h3> <br>
                  </div>
                  <div class="services-img">
                    <img src="assets/img/Solar.jpg" height="250" alt="services-img">
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3 ><span class="borders-top">Lighting</span>  Electrified <br> Transportation</h3> <br>                            
                    </div>
                    <div class="services-img">
                        <img src="assets/img/Lighting.jpg" height="250" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3><span class="borders-top">Energy</span> <br> Storage</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/EnergyStorage.jpg" height="250" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3><span class="borders-top">LED</span> <br><br></h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/LED.jpg" height="250" alt="services-img">
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
