import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private baseUrl = 'http://localhost:4200'; // Reemplaza con la URL de tu servidor Node.js

  constructor(private http: HttpClient) {}

  enviarCorreo(destinatario: string, asunto: string, mensaje: string): Observable<any> {
    const correo = {
      destinatario: destinatario,
      asunto: asunto,
      mensaje: mensaje,
    };

    return this.http.post(`${this.baseUrl}/enviar-correo`, correo);
  }
}
